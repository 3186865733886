import React, { Component } from "react";
import {
  Layout,
  Icon,
  Button,
  Select,
  Card,
  Col,
  Row,
  Drawer,
  Form,
} from "antd";
import logo from "../static/images/verkoopnou-logo.png";
import Whatsapp from "../static/images/whatsapp-icon.svg";
import mapRectangle from "../static/images/map-rectangle.png";
import "./sales-screen.css";
import { Link } from "react-router-dom";
import validator from "validator";
import TextField from "@material-ui/core/TextField";
import PDF from "../Verkoopnou_Website_Terms_and_Conditions.pdf";
const sgMail = require("@sendgrid/mail");

const { Header, Content, Footer } = Layout;
const FormItem = Form.Item;
const TopHeader = () => {
  return (
    <Header className="header-home">
      <a href="/">
        <img src={logo} alt="Logo" className="verkoopnou-logo" />
      </a>
      <h1 className="verkoopnou-logo-text">verkoopnou.co.za</h1>
      <div></div>
      <div className="header-links">
        <Link to="/">
          <a style={{ paddingLeft: "1.5em" }}>
            {window.i18n.translate("Home")}
          </a>{" "}
        </Link>
        <a href="#middle">
          <a style={{ paddingLeft: "1.5em" }}>
            {" "}
            {window.i18n.translate("HowItWorks")}
          </a>
        </a>
        <a
          style={{ paddingLeft: "1.5em" }}
          href="https://dev.verkoopnou.co.za/app/signup?type=dealer"
        >
          {window.i18n.translate("BeADealer")}
        </a>{" "}
        <Link to="/contact-us">
          <a style={{ paddingLeft: "1.5em" }}>
            {window.i18n.translate("ContactUs")}
          </a>
        </Link>
      </div>{" "}
      <a href="https://dev.verkoopnou.co.za/app/signin?type=customer">
        <Button
          id="loginButtons"
          className="header-button-customer"
          type="primary"
        >
          {window.i18n.translate("CustomerLogin")}
        </Button>
      </a>
      <a href="https://dev.verkoopnou.co.za/app/signin?type=dealer">
        <Button
          style={{
            background: "white",
            color: "#00B4BC",
          }}
          id="loginButtons"
          className="header-button-dealer"
          type="primary"
        >
          {window.i18n.translate("DealerLogin")}
        </Button>
      </a>
    </Header>
  );
};
const BottomFooter = () => {
  return (
    <Footer className="bottom-footer">
      <img src={logo} alt="Logo" className="footer-logo" />
      <div className="footer-links">
        <a href="/">
          <a style={{ color: " #00888e" }}>{window.i18n.translate("Home")}</a>
        </a>
        <br />
        <a href="#middle">
          <a style={{ color: " #00888e" }}>
            {window.i18n.translate("HowItWorks")}
          </a>
        </a>
        <br />
        <a
          style={{ color: " #00888e" }}
          href="https://dev.verkoopnou.co.za/app/signup?type=dealer"
        >
          {window.i18n.translate("BeADealer")}
        </a>{" "}
        <br />{" "}
        <Link to="/contact-us">
          <a style={{ color: " #00888e" }}>
            {" "}
            {window.i18n.translate("ContactUs")}
          </a>
        </Link>
      </div>
      <div className="footer-links-div">
        <a style={{ color: " #00888e" }}> {window.i18n.translate("Help")}</a>
        <br />
        <a target="_blank" href={PDF} style={{ color: " #00888e" }}>
          {window.i18n.translate("TermsConditions")}
        </a>
        <br />
        <a style={{ color: " #00888e" }}>
          {window.i18n.translate("PrivacyPolicy")}
        </a>
        <br />
      </div>
      <div className="footer-text">
        <p style={{ color: "#00888E" }}>
          13 Joe Slovo Rd,CBD
          <br />
          Klerksdorp
          <br /> 2570
          <br /> <br /> 060 VERKOOP ( 837 5667 )
          <br /> <br />
          info@verkoopnou.co.za
        </p>{" "}
        <a href="https://www.facebook.com/verkoopnou">
          <Icon
            type="facebook"
            theme="filled"
            style={{ paddingRight: "0.5em", fontSize: "2em", color: "#00888E" }}
            url="https://www.facebook.com/verkoopnou/"
          />
        </a>
      </div>
      <div style={{ textAlign: "center" }} className="footer-watermark">
        <p style={{ color: "grey" }}>
          © 2020 VerkoopNou | {window.i18n.translate("Powered")}{" "}
          <Link to="https://www.venturepoint.co.za">
            <a style={{ color: "grey" }}> Venturepoint Consulting Services</a>
          </Link>
          (PTY) LTD
        </p>
      </div>
    </Footer>
  );
};
function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}
function handleChange(value) {
  console.log(`selected ${value}`);
}
const SENDGRID_API_KEY =
      "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";
sgMail.setApiKey(SENDGRID_API_KEY);
class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      successModal: false,
      formValidity: false,
      submitDisabled: false,
      visible: false,
      placement: "left",
      name: "",
      email: "",
      number: "",
      company: "",
      message: "",
    };

    

    this.changeValue = this.changeValue.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.onSubmit(values);
      }
    });
  };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  componentDidMount() {
    document.body.scrollTop = 0;
  }

  changeValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handleCloseSuccessModal = () => {
    this.setState({ successModal: false });
  };
  onSubmit = (values) => {

    const MSG = {
      to: ["ianels182@gmail.com"],
      from: "info@epic.co.za",
      subject: "New contact request",
      text: " ",
      html: `
    
      <style type="text/css">  
            body, #bodyTable{background-color:white}
            #emailHeader{background-color:white}
            #emailBody{background-color:#FFFFFF; border:1px solid #CCCCCC;}
            #emailFooter{background-color:#E1E1E1;}      
    </style>
  <body bgcolor="#E1E1E1" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
    <center style="background-color:#E1E1E1;">
      <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
        <tr>
          <td align="center" valign="top" id="bodyCell">      
            <table bgcolor="#E1E1E1" border="0" cellpadding="0" cellspacing="0" width="500" id="emailHeader">
              <!-- HEADER ROW // -->
              <tr>
                <td align="center" valign="top">
                  <!-- CENTERING TABLE // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <td align="center" valign="top">
                        <!-- FLEXIBLE CONTAINER // -->
                        <table border="0" cellpadding="10" cellspacing="0" width="500" class="flexibleContainer">
                          <tr>
                            <td valign="top" width="500" class="flexibleContainerCell">
  
                              <!-- CONTENT TABLE // -->
                              <table align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                                <tr>
                             
                                  <td align="right" valign="middle" class="flexibleContainerBox">
                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:100%;">
                                      <tr>
                                        <td align="left" class="textContent">
                                          <!-- CONTENT // -->
                                         
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <!-- // FLEXIBLE CONTAINER -->
                      </td>
                    </tr>
                  </table>
                  <!-- // CENTERING TABLE -->
                </td>
              </tr>
              <!-- // END -->
            </table>     
            <!-- EMAIL BODY // -->   
            <table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="500" id="emailBody">
              <!-- MODULE ROW // -->
              <tr>
                <td align="center" valign="top">
                  <!-- CENTERING TABLE // -->
                  <table border="0" cellpadding="0" cellspacing="0"   style="color:#FFFFFF;" bgcolor="#744a31">
                    <tr>
                      <td align="center" valign="top">
                        <!-- FLEXIBLE CONTAINER // -->
                       
                        <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                          <tr>
                            <td align="center" valign="top" width="500" class="flexibleContainerCell">
  
                              <!-- CONTENT TABLE // -->
                            
                              <table border="0" cellpadding="0" cellspacing="0" width="100%" z-index="100" >
                                     <td align="center" valign="top" width="500" class="flexibleContainerCell  ">   
                                      <img src="https://www.epictimber.co.za/assets/epic-timber-logo.png"/>    <tr>
                                  <td align="center" valign="top" class="textContent">
                                    <h1 style="color:#FFFFFF;line-height:100%;font-family:Helvetica,Arial,sans-serif;font-size:35px;font-weight:normal;margin-bottom:20px;text-align:center;">Contact Request</h1>
                                    <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:17px;margin-bottom:10px;color: #FFFFFF;line-height:135%;">You have received a new contact request from <br /> the Epic Timber website</h2>
                     
                                  </td>
                                </tr>
                              </table>
                              <!-- // CONTENT TABLE -->
  
                            </td>
                          </tr>
                        </table>
                        <!-- // FLEXIBLE CONTAINER -->
                      </td>
                    </tr>
                  </table>
                  <!-- // CENTERING TABLE -->
                </td>
              </tr>

              <!-- MODULE ROW // -->
              
              <tr mc:hideable>
                <td align="center" valign="top">
                  <!-- CENTERING TABLE // -->
                  <table border="0" cellpadding="0" cellspacing="0" width="100%" height="300" >
                    <tr>
                      <td align="center" valign="top">
                        <!-- FLEXIBLE CONTAINER // -->
                        <table border="0" cellpadding="0" cellspacing="0" width="300" class="flexibleContainer">
                          <tr>
                            <td valign="top" width="500" class="flexibleContainerCell">
                              <!-- CONTENT TABLE // -->
                              <table p align="left" border="0" cellpadding="0" cellspacing="0" width="100%">
                                <tr>
                                  <td align="center" valign="top" class="flexibleContainerBox">
                                   	<table border="0" cellspacing="0" cellpadding="0" >
									
															<tr style="font-family: sans-serif; font-size: 15px; letter-spacing: 1px; color:rgb(121, 121, 121)">
																<td class="role"><br /><br /><br /><br /><br />
                                <strong> Name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.name}<br />
                                     <strong> Email </strong>&nbsp;&nbsp;: &nbsp;&nbsp;${values.email}<br />
                                     <strong> Number </strong>&nbsp;&nbsp; :                     &nbsp;&nbsp;${values.number}<br />
                                     <strong> Company name </strong> &nbsp;&nbsp;:&nbsp;&nbsp; ${values.company}<br />
                                     <strong> Message </strong>&nbsp;&nbsp;:&nbsp;&nbsp; ${values.message}<br /><br /><br /><br /><br />
																</td>
															</tr>
															<table>
                                  </td>
                                </tr>
                              </table>
                              <!-- // CONTENT TABLE -->
  
                            </td>
                          </tr>
                        </table>
                        <!-- // FLEXIBLE CONTAINER -->
                      </td>
                    </tr>
                  </table>
                  <!-- // CENTERING TABLE -->
                </td>
              </tr>
              <!-- // MODULE ROW -->
  
  
              <!-- MODULE ROW // -->
             
<table border="0" cellpadding="0" cellspacing="0" width="100%"  style="color:#FFFFFF;" bgcolor="#303030">
                    <tr>
                      <td align="center" valign="top">
                      
                        <table border="0" cellpadding="0" cellspacing="0" width="500"   class="flexibleContainer">
                          <tr>
                            <td align="center" valign="top" width="500" class="flexibleContainerCell">
  
                          
                              <table border="0" cellpadding="30" cellspacing="0" width="100%">
                            
													
                                <tr>
                                  <td align="center" valign="top" class="textContent">
              
                                    <h2 style="text-align:center;font-weight:normal;font-family:Helvetica,Arial,sans-serif;font-size:13px;margin-bottom:0px;color: lightgray;line-height:135%;">&#9400 2019 Epic Timber</h2>
                     
                                  </td>
                                </tr>
                              </table>
                              <!-- // CONTENT TABLE -->
  
                            </td>
                          </tr>
                        </table>
                        <!-- // FLEXIBLE CONTAINER -->
                      </td>
                    </tr>
                  </table>
           `,
    };
    sgMail.send(MSG).then(() => {
      console.log("success")
    }).catch(err => {
      console.log("error")
    })
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;

    return (
      <React.Fragment>
        <div
          style={{
            margin: "auto",
            top: "0em",
            maxWidth: "105em",
          }}
        >
          {" "}
          <Icon
            className="burger-menu"
            style={{
              visibility: "hidden",
              zIndex: 1,
              fontSize: "2em",
              position: "absolute",
              right: "0.7em",
              top: "0.7em",
              color: "white",
            }}
            type="menu"
            onClick={this.showDrawer}
          ></Icon>
          <Drawer
            style={{ textAlign: "center" }}
            placement={this.state.placement}
            closable={true}
            onClose={this.onClose}
            visible={this.state.visible}
          >
            <img src={logo} alt="Logo" className="verkoopnou-logo-menu" />
            <h1 className="verkoopnou-logo-text-menu">verkoopnou.co.za</h1>
            <Link to="/">
              <a style={{ fontSize: "20px" }}>
                {window.i18n.translate("Home")}
              </a>
            </Link>
            <br /> <br />
            <a href="#middle">
              <a style={{ fontSize: "20px" }}>
                {window.i18n.translate("HowItWorks")}
              </a>
            </a>
            <br /> <br />
            <a
              style={{ fontSize: "20px" }}
              href="https://dev.verkoopnou.co.za/app/signup?type=dealer"
            >
              {window.i18n.translate("BeADealer")}
            </a>{" "}
            <br /> <br />
            <Link to="/contact-us">
              <a style={{ fontSize: "20px" }}>
                {window.i18n.translate("ContactUs")}
              </a>
            </Link>
            <br /> <br />
            <a href="https://dev.verkoopnou.co.za/app/signin?type=customer">
              <Button
                style={{
                  fontSize: "17px",
                  margin: "auto",
                  marginTop: "1em",
                  height: "2.7em",
                  borderRadius: "3em",
                  width: "9em",
                }}
                type="primary"
              >
                {window.i18n.translate("CustomerLogin")}
              </Button>
            </a>{" "}
            <br /> <br />
            <a href="https://dev.verkoopnou.co.za/app/signin?type=dealer">
              <Button
                style={{
                  margin: "auto",
                  fontSize: "17px",
                  background: "white",
                  color: "#00B4BC",
                  height: "2.7em",
                  borderRadius: "3em",
                  width: "11em",
                }}
                type="primary"
              >
                {window.i18n.translate("DealerLogin")}
              </Button>
            </a>
          </Drawer>
          <TopHeader />{" "}
        </div>
        <Content style={{ padding: "0 50px", zIndex: -99 }}>
          <img src={mapRectangle} alt="Logo" className="map-rectangle" />
          <Row
            gutter={24}
            style={{
              overflow: "hidden",
              marginLeft: "-48px",
              marginRight: "0px",
            }}
          >
            <Col span={24} style={{ paddingLeft: "50px", paddingRight: "0px" }}>
              {" "}
              <Card
                className="message-card"
                style={{
                  margin: "auto",
                  borderRadius: "2em",
                  width: "80%",
                  minWidth: "60em",
                  maxWidth: "80em",
                  height: "40em",
                  marginBottom: "15em",
                  top: "14em",
                  boxShadow: "3px 4px 10px 2px #88888880",
                  zIndex: 99,
                }}
                bordered={false}
              >
                <h1
                  className="message-card-header"
                  style={{
                    fontSize: "28px",
                    fontWeight: 400,
                    marginLeft: "1.6em",
                    marginTop: "1.5em",
                    color: "#00B4BC",
                    fontWeight: "700",
                  }}
                >
                  {window.i18n.translate("SendUsAMessage")}
                </h1>
                <Form style={{ marginTop: "3em" }} onSubmit={this.handleSubmit}>
                  <Row gutter={24}>
                    <Col span={7}>
                      <FormItem style={{ top: "1em", marginLeft: "3em" }}>
                        {getFieldDecorator("name", {
                          rules: [
                            {
                              required: true,
                              message: window.i18n.translate("NameRequired"),
                            },
                          ],
                        })(
                          <TextField
                            style={{
                              width: "100%",
                              minWidth: "10.5em",
                              top: "0em",
                            }}
                            id="standard-basic"
                            label={window.i18n.translate("YourName")}
                          />
                        )}{" "}
                      </FormItem>
                    </Col>{" "}
                    <Col span={7}>
                      <FormItem style={{ top: "1em", marginLeft: "3em" }}>
                        {getFieldDecorator("email", {
                          rules: [
                            {
                              required: true,
                              message: window.i18n.translate("EmailRequired"),
                            },
                          ],
                        })(
                          <TextField
                            style={{
                              width: "100%",
                              minWidth: "10.5em",
                              top: "0em",
                            }}
                            id="standard-basic"
                            label={window.i18n.translate("EmailAddress")}
                          />
                        )}{" "}
                      </FormItem>
                    </Col>
                  </Row>{" "}
                  <Row gutter={24} style={{ top: "2em" }}>
                    <Col span={7}>
                      <FormItem style={{ marginLeft: "3em" }}>
                        {getFieldDecorator("number", {
                          rules: [
                            {
                              required: true,
                              message: window.i18n.translate("NumberRequired"),
                            },
                          ],
                        })(
                          <TextField
                            style={{
                              width: "100%",
                              minWidth: "10.5em",
                              top: "0em",
                            }}
                            id="standard-basic"
                            label={window.i18n.translate("PhoneNumber")}
                          />
                        )}{" "}
                      </FormItem>{" "}
                    </Col>
                    <Col span={7}>
                      <FormItem style={{ marginLeft: "3em" }}>
                        {getFieldDecorator("company", {
                          rules: [
                            {
                              required: false,
                            },
                          ],
                        })(
                          <TextField
                            style={{
                              width: "100%",
                              minWidth: "10.5em",
                              top: "0em",
                            }}
                            id="standard-basic"
                            label={window.i18n.translate("Company")}
                          />
                        )}{" "}
                      </FormItem>{" "}
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={14} style={{ top: "4em" }}>
                      <FormItem style={{ marginLeft: "3em" }}>
                        {getFieldDecorator("message", {
                          rules: [
                            {
                              required: false,
                            },
                          ],
                        })(
                          <TextField
                            style={{
                              width: "100%",
                              minWidth: "23em",
                              top: "0em",
                            }}
                            id="standard-basic"
                            label={window.i18n.translate("Message")}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Button
                    disabled={hasErrors(getFieldsError())}
                    htmlType="submit"
                    style={{
                      position: "absolute",
                      fontSize: "20px",
                      left: "20%",
                      marginTop: "6em",
                      background: "#00B4BC",
                      color: "white",
                      borderRadius: "2em",
                      width: "20%",
                      height: "2.5em",
                      paddingTop: "0px",
                    }}
                    id="loginButton"
                    className="header-button-send"
                    type="primary"
                  >
                    {window.i18n.translate("Send")}
                  </Button>
                </Form>

                <Card
                  className="contact-info-card"
                  style={{
                    borderBottomRightRadius: "2em",
                    borderTopRightRadius: "2em",
                    width: "40%",
                    backgroundColor: "#00888E",
                    marginLeft: "63%",
                    height: "40em",
                    marginBottom: "-19em",
                    top: "-24em",
                  }}
                  bordered={false}
                >
                  {" "}
                  <div className="contact-text">
                    <h1
                      style={{
                        fontSize: "26px",
                        fontWeight: 400,
                        marginLeft: "0em",
                        marginBottom: "2em",
                        color: "white",
                        fontWeight: "700",
                      }}
                    >
                      {window.i18n.translate("ContactInformation")}
                    </h1>
                    <p style={{ color: "#ffffffe5" }}>
                      <Icon type="environment" theme="filled" />
                      &nbsp; 13 Joe Slovo Rd, CBD, <br />
                      &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;Klerksdorp, 2570
                      <br /> <br /> <br />
                      <img
                        style={{ width: "1.1em" }}
                        src={Whatsapp}
                        theme="filled"
                      />
                      &nbsp;&nbsp; 060 VERKOOP ( 837 5667 )
                      <br />
                      <br /> <br />
                      <Icon type="mail" theme="filled" />
                      &nbsp; &nbsp;info@verkoopnou.com
                    </p>{" "}
                    <a href="https://www.facebook.com/verkoopnou">
                      <Icon
                        className="facebook-icon"
                        type="facebook"
                        theme="filled"
                        style={{
                          marginTop: "3em",
                          paddingRight: "1em",
                          fontSize: "1.5em",
                          color: "white",
                        }}
                      />
                    </a>
                  </div>
                </Card>
              </Card>
            </Col>
          </Row>{" "}
          <BottomFooter />
        </Content>
      </React.Fragment>
    );
  }
}
const WrappedHorizontalLoginForm = Form.create({ name: "horizontal_login" })(
  ContactUs
);
export default WrappedHorizontalLoginForm;
